import Vue from "vue";

import "./styles/quasar.scss";
import "quasar/dist/quasar.ie.polyfills";
import "@quasar/extras/material-icons/material-icons.css";
import Quasar from 'quasar/src/vue-plugin.js';import Dialog from 'quasar/src/plugins/Dialog.js';import Notify from 'quasar/src/plugins/Notify.js';;

Vue.use(Quasar, {
  config: {},
  plugins: {
    Dialog,
    Notify,
  },
});
